import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';

import repositoryConfigs from './src/prismicPreviews';
import { linkResolver } from './src/linkResolver';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
    {element}
  </PrismicPreviewProvider>
);

const HeadComponents = [
  <script
    key="cognito-script"
    src="https://www.cognitoforms.com/f/seamless.js"
    data-key="ZzIdNcZP10K4STqir9adsA"
  />,
];

const PostComponents = [
  <script
    key="cckey"
    dangerouslySetInnerHTML={{
      __html: `var _ctct_m = 'eddff7e6a41f0c4c350c8ddf8c4017f4'`,
    }}
  />,
  <script
    key="ccscript"
    id="signupScript"
    src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
    async
    defer
  />,
  <script
    key="bootstrapJS"
    src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
    crossOrigin="anonymous"
  />,
];

export const onRenderBody = (
  { setHeadComponents, setPostBodyComponents },
  pluginOptions
) => {
  setHeadComponents(HeadComponents);
  setPostBodyComponents(PostComponents);
};
