exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'general_content_page') {
    return `/${doc.uid}`;
  }

  // URL for a product type
  if (doc.type === 'home_page') {
    return `/`;
  }

  if (doc.type === 'sandbox') {
    return `/sandbox`;
  }

  if (doc.type === 'airport_guide') {
    return `/airport-guide`;
  }

  if (doc.type === 'getting_to_and_from') {
    return `/getting-to-and-from`;
  }

  if (doc.type === 'flight_information') {
    return `/flight-information`;
  }

  if (doc.type === 'parking') {
    return `/parking`;
  }

  if (doc.type === 'about_us') {
    return `/about-us`;
  }

  if (doc.type === 'airlines') {
    return `/airlines`;
  }

  if (doc.type === 'public_art_and_history') {
    return `/public-art`;
  }

  if (doc.type === 'shops_dining') {
    return `/shops-and-dining`;
  }

  if (doc.type === 'terminal_how-tos') {
    return `/terminal-how-tos`;
  }

  if (doc.type === 'newsroom_article') {
    return `/newsroom/news-releases/${doc.uid}`;
  }

  if (doc.type === 'newsroom') {
    return `/newsroom`;
  }

  if (doc.type === 'category') {
    return `/newsroom?category=${doc.uid}`;
  }

  // URL for a page type
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }

  // Backup for all other types
  return '/';
};
