/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
import { linkResolver } from './linkResolver';

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
const repositoryConfigs = [
  {
    repositoryName: 'flykc',
    accessToken:
      'MC5ZeEp1QXhJQUFDb0FLWE1R.QRtkT0tSQO-_ve-_ve-_vU11YCclPjjvv71-WDDvv73vv73vv719M--_vS_vv706Nzk',
    linkResolver,
    componentResolver: {
      general_content_page: import('./templates/GeneralContentPage'),
    },
  },
];

export default repositoryConfigs;
